<template>
  <BaseModal v-model="model" max-width="550px">
    <div class="upgrade-modal__text">{{ t('global.upgradeToProText') }}</div>
    <div class="upgrade-modal__action-container"><UpgradeButton /></div>
  </BaseModal>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useStore } from 'vuex';
import BaseModal from '@/components/BaseModal.vue';
import UpgradeButton from '@/features/Header/components/UpgradeButton.vue';

const store = useStore();
const { t } = useI18n();

const model = computed({
  get: () => store.state.upgradeToProModal,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', {
      property: 'upgradeToProModal',
      value: val,
    });
  },
});
</script>
<style lang="scss" scoped>
.upgrade-modal {
  &__text {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  &__action-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
}
</style>
