<template>
  <v-table>
    <thead>
      <tr>
        <th>{{ t('answerFormatting.outputTemplateName') }}</th>
        <th>{{ t('answerFormatting.content') }}</th>
        <th>{{ t('answerFormatting.creator') }}</th>
        <th>{{ t('answerFormatting.lastEdit') }}</th>
        <th>{{ t('answerFormatting.actions') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="outputTemplate in outputTemplateListResource?.outputTemplates || []"
        :key="outputTemplate.id">
        <td class="name-cell" @click.prevent="onUseTemplate(outputTemplate)">
          <a class="text-decoration-underline text-truncate">
            {{ outputTemplate.name }}
          </a>
        </td>
        <td class="prompt-column">
          <span class="text-truncate">
            {{ outputTemplate.prompt }}
          </span>
        </td>
        <td class="creator">
          <OutputTemplateCreator :owned-by-type="outputTemplate.ownedByType" />
        </td>
        <td>
          {{ relativeTimeFormatter.format(Date.parse(outputTemplate.updatedAt)) }}
        </td>
        <td class="actions">
          <OutputTemplateToolbarAction
            icon="mdi-rename"
            @click="onRename(outputTemplate)"
            :disabled="!isOutputTemplateEditable(outputTemplate)" />
          <Dialog
            @click:confirm="onDelete(outputTemplate)"
            :title="t('answerFormatting.deleteOutputTemplate')"
            :card-text="
              t('answerFormatting.deleteOutputTemplateDescription', { name: outputTemplate.name })
            "
            :confirm-button-text="t('global.delete')"
            button-color="error">
            <template #activator="{ openDialog }">
              <OutputTemplateToolbarAction
                icon="mdi-delete"
                @click="openDialog"
                :disabled="!isOutputTemplateEditable(outputTemplate)" />
            </template>
          </Dialog>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup>
import TimeAgo from 'javascript-time-ago';
import { useI18n } from 'vue-i18n';
import Dialog from '@/components/Dialog.vue';
import OutputTemplateCreator from '@/features/OutputTemplate/components/OutputTemplateCreator.vue';
import OutputTemplateToolbarAction from '@/features/OutputTemplate/components/OutputTemplateToolbarAction.vue';
import { isOutputTemplateEditable } from '@/utils/outputTemplate';

defineProps({
  outputTemplateListResource: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const emit = defineEmits(['rename', 'delete', 'useTemplate']);

const { t, locale } = useI18n();
const relativeTimeFormatter = new TimeAgo(locale.value);

const onRename = (outputTemplateToRename) => {
  emit('rename', outputTemplateToRename);
};

const onDelete = (outputTemplateToDelete) => {
  emit('delete', outputTemplateToDelete);
};

const onUseTemplate = (outputTemplate) => {
  emit('useTemplate', outputTemplate);
};
</script>

<style lang="scss">
.creator {
  text-align: center;
}

.actions {
  display: flex;
}

.prompt-column {
  max-width: rem(300px);
  min-width: 0;

  & > span {
    display: block;
  }
}

.name-cell {
  max-width: rem(200px);

  a {
    cursor: pointer;
    display: block;
    justify-content: start;
  }

  span.v-btn__content {
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
