module.exports = {
  routes: [
    {
      path: '/browse',
      name: 'Search',
      component: () => import('../views/Browse.vue'),
      meta: { title: 'Browse Page' },
      props(route) {
        return { qid: route.query.qid, rid: route.query.rid };
      },
    },
    {
      path: '/',
      name: 'Home',
      beforeEnter: (to, from, next) => {
        const sid = to.query.sid;
        if (sid) {
          next({
            path: '/chat',
            query: { sid },
            replace: true,
          });
        } else {
          next();
        }
      },
      component: () => import('../views/Home.vue'),
      meta: { requiresAuth: false, title: 'Home page', isFooterVisible: true },
    },
    {
      path: '/chat',
      name: 'Chat',
      component: () => import('../views/Chat.vue'),
      meta: { requiresAuth: false, title: 'Chat with protocols and abstracts' },
      props(route) {
        return { sid: route.query.sid, chatWithReferences: route.query.chatWithReferences };
      },
    },
    {
      path: '/solutions',
      name: 'Solutions',
      component: () => import('../views/Solutions.vue'),
      meta: { title: 'Solutions Page', isFooterVisible: true },
    },
    {
      path: '/knowledge-base',
      children: [
        {
          path: '',
          name: 'Knowledge Base',
          component: () => import('../views/KnowledgeBase.vue'),
          meta: { title: 'Knowledge Base', isFooterVisible: true },
        },
        {
          path: ':id',
          name: 'Knowledge Base Article',
          component: () => import('../views/KnowledgeBaseArticle.vue'),
          meta: { title: 'Knowledge Base Article', isFooterVisible: true },
        },
      ],
    },
    {
      path: '/blog',
      children: [
        {
          path: '',
          name: 'Blog',
          component: () => import('../views/Blog.vue'),
          meta: { title: 'News & Blog Page', isFooterVisible: true },
        },
        {
          path: ':id',
          name: 'BlogArticle',
          component: () => import('../views/BlogArticle.vue'),
          meta: { title: 'News & Blog Article', isFooterVisible: true },
        },
      ],
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('../views/About.vue'),
      meta: { title: 'About Page', isFooterVisible: true },
    },
    {
      path: '/userprofile',
      name: 'UserProfile',
      component: () => import('../views/UserProfile.vue'),
      meta: { requiresAuth: true, title: 'User Profile Page' },
    },
    /*{
      path: '/updates',
      name: 'Updates',
      component: () => import('../views/Updates.vue'),
      meta: { title: 'Updates Page' },
    },*/
    /*{
      path: '/library',
      name: 'Library',
      component: () => import('../views/Library.vue'),
      meta: { requiresAuth: true, title: 'Library Page' },
    },*/
    {
      path: '/terms',
      name: 'Terms',
      component: () => import('../views/Terms.vue'),
      meta: { title: 'Terms of Use', isFooterVisible: true },
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('../views/Privacy.vue'),
      meta: { title: 'Privacy Policy', isFooterVisible: true },
    },
    {
      path: '/auth-action',
      name: 'Auth Action',
      meta: { title: 'Auth Action' },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue'),
      meta: { title: 'Login Page', sideBanner: true },
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('../views/Register.vue'),
      meta: { title: 'Register Page', sideBanner: true },
    },
    {
      path: '/reset',
      name: 'Reset',
      component: () => import('../views/Reset.vue'),
      meta: { title: 'Reset Page', sideBanner: true },
    },
    {
      path: '/verification',
      name: 'Verification',
      component: () => import('../views/Verification.vue'),
      meta: { title: 'Verification Email Page', sideBanner: true },
    },
    {
      path: '/customAuthAction',
      name: 'customAuthAction',
      component: () => import('../views/CustomAuthAction.vue'),
      meta: { title: 'Auth Action Page', sideBanner: true },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('../views/Logout.vue'),
      meta: { title: 'Logout Page', requiresAuth: true },
    },
    {
      path: '/unsubscribe',
      name: 'Unsubscribe',
      component: () => import('../views/Unsubscribe.vue'),
      meta: { title: 'Unsubscribe Page', requiresAuth: true },
    },
    {
      path: '/unsubscribed',
      name: 'Unsubscribed',
      component: () => import('../views/Unsubscribed.vue'),
      meta: { title: 'Unsubscribed Page', requiresAuth: true },
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: () => import('../views/Pricing.vue'),
      meta: { title: 'Pricing Page', isFooterVisible: true },
    },
    {
      path: '/organizations',
      name: 'Organizations',
      component: () => import('../views/Organizations.vue'),
      meta: { title: 'Organizations', requiresAuth: true, requiresSuperAdmin: true },
    },
    {
      path: '/users',
      name: 'Users',
      component: () => import('../views/Users.vue'),
      meta: { title: 'Users', requiresAuth: true, requiresSuperAdmin: true },
    },
    {
      path: '/organization',
      children: [
        {
          path: ':id',
          name: 'OrganizationSettings',
          component: () => import('../views/OrganizationSettings.vue'),
          meta: {
            title: 'Organization settings',
            requiresAuth: true,
            requiresOrganizationId: true,
          },
        },
      ],
    },
    {
      path: '/sources',
      name: 'Sources',
      component: () => import('../views/Sources.vue'),
      meta: { title: 'Sources' },
    },
    {
      path: '/source',
      children: [
        {
          path: ':id',
          name: 'OrganizationSource',
          component: () => import('../views/OrganizationSource.vue'),
          meta: { title: 'Organization source', requiresAuth: true },
        },
      ],
    },
    /*{
      path: '/unauthorized',
      name: 'Unauthorized',
      component: () => import('../views/Unauthorized.vue'),
      meta: { title: 'Unauthorized' },
    },*/
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../views/NotFound.vue'),
      meta: {
        title: '404',
        // Explicitly leave this out of the URL map used in the HTTPS proxy.
        // The HTTPS proxy already has a catch-all for 404 routes.
        // This route is still necessary inside the application, just not inside the HTTPS proxy.
        skipUrlMap: true,
      },
    },
  ],
};
