<template>
  <BaseModal v-model="isModalOpenModel" :title="title">
    <form @submit.prevent="onConfirm">
      <BaseInput v-model="nameInputModel" :label="t('answerFormatting.outputTemplateName')" />
      <div class="actions-container">
        <BaseButton class="cancel-button" color="primary" variant="outlined" @click="onCancel">{{
          t('global.cancel')
        }}</BaseButton>
        <BaseButton class="confirm-button" :type="submit">{{ confirmButtonTitle }}</BaseButton>
      </div>
    </form>
  </BaseModal>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import BaseButton from '@/components/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import BaseInput from '@/components/form/BaseInput.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
  confirmButtonTitle: {
    type: String,
    required: true,
  },
});

const isModalOpenModel = defineModel('isModalOpenModel', { required: true, type: Boolean });
const nameInputModel = defineModel('nameInputModel', { required: true, type: String });
const emit = defineEmits(['cancel', 'confirm']);

const { t } = useI18n();

const onCancel = () => {
  emit('cancel');
};

const onConfirm = () => {
  emit('confirm');
};
</script>

<style lang="scss">
.actions-container {
  display: flex;
  gap: rem(8px);
  margin-top: rem(16px);

  & .cancel-button {
    margin-left: auto;
  }
}
</style>
