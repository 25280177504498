const EXAMPLE_QUESTIONS = ['1', '2', '3'];
const FOLLOWUP_ACTIONS = ['1'];
const extractReferencesFromResponse = (text, replaceWithFunction) => {
  // Remove
  // [id1234]  -> remove 'id'
  // [id 1234] -> remove 'id '
  // [id:1234] -> remove 'id:'
  // [id: 1234] -> remove 'id: '
  // [ID1234] -> remove 'ID'
  // [ID 1234] -> remove 'ID '
  // [ID:1234] -> remove 'ID:'
  // [ID: 1234] -> remove 'ID: '
  // [Id1234] -> remove 'ID'
  // [Id 1234] -> remove 'ID '
  // [Id:1234] -> remove 'ID:'
  // [Id: 1234] -> remove 'ID: '
  // [^1234]

  const regexID = /\[\s*(id|\^|#)+(\s*:?\s*)?(\d+)\s*\]/gi;

  // Remove
  // (ID: 33757520) -> [33757520]

  const regexIDParens = /\(\s*(id|\^|#)+(\s*:?\s*)?(\d+)\s*\)/gi;

  return (
    text
      .replace(regexID, '[$3]') // See questionHelpers.test.js for examples
      .replace(regexIDParens, '[$3]') // See questionHelpers.test.js for examples
      .replace(/\\u(\d{4})/g, '\\u$1')
      .replace(/\[\\\[id\\\]\]\((\d+)\)/g, (_, match) => `[${match}]`) // TODO remove when chat answer is fixed
      .replace(/\[\\\[id\\\]\]/g, () => '*') // TODO remove when chat answer is fixed
      .replace(/\[(id|ID|Id)(:|)(number|NUMBER)\]/g, () => '*') // TODO remove when chat answer is fixed
      .replace(/\[(id|ID|Id)\]/g, () => '*') // TODO remove when chat answer is fixed
      //For the array of [id: 1234, ID 12334, Id:125] -> [1234,12334,125]
      .replace(/\[(?:\s*id\s*:?\s*\d+\s*,?)+\s*\]/gi, (match) => {
        // Find all the ids in the match and join them with a comma
        const ids = match.match(/\d+/g);
        return `[${ids.join(',')}]`;
      })
      .replace(/\[([\d\s,]+)\]/g, (_, match) => replaceWithFunction(match))
  );
};

const CHAT_RESPONSE_LANGUAGE_BY_DEFAULT = 'en';

const NO_RESULT_CONSTANT = 'NO_RESULT';

const isItNoResult = (content) => content === NO_RESULT_CONSTANT;

const NUMBER_LIMIT_OF_SELECTED_DOCUMENTS_FOR_REGENERATE_ANSWER = 15;

const findTheLanguagesOfSelectedSources = (selectedSources, sources, webSearchSources) => {
  const sourceLanguages = new Set();
  const sourcePath = sources.keys()?.next()?.value?.split('/') || [];
  const idPrefix = sourcePath.length
    ? sourcePath.slice(0, sourcePath.length - 1).join('/') + '/'
    : '';

  selectedSources.forEach((source) => {
    if (source.charAt(0) === '*') {
      sourceLanguages.add(...webSearchSources.languages);
    } else {
      const sourceAccessId = idPrefix + source;
      const foundSource = sources.get(sourceAccessId);

      if (foundSource) {
        for (const language of foundSource.languages) {
          sourceLanguages.add(language);
        }
      }
    }
  });

  if (sourceLanguages.size === 1) {
    return sourceLanguages.values().next().value;
  }

  return 'en';
};

const MULTI_LANG_CODE = 'multi';

const getSourcesMapByLanguage = (sources) => {
  const mapByLang = new Map();
  sources.forEach((source) => {
    if (source.languages.length > 1) {
      if (mapByLang.has(MULTI_LANG_CODE)) {
        const existingSource = mapByLang.get(MULTI_LANG_CODE);
        existingSource.push(source);
        return;
      }
      mapByLang.set(MULTI_LANG_CODE, [source]);
    } else if (source.languages.length === 1) {
      const langCode = source.languages[0];
      if (mapByLang.has(langCode)) {
        const existingSource = mapByLang.get(langCode);
        existingSource.push(source);
        return;
      }
      mapByLang.set(source.languages[0], [source]);
    }
  });
  return mapByLang;
};

const getSourcesArrayByLanguage = (map, langCode) => map.get(langCode) || [];
export {
  FOLLOWUP_ACTIONS,
  EXAMPLE_QUESTIONS,
  CHAT_RESPONSE_LANGUAGE_BY_DEFAULT,
  NO_RESULT_CONSTANT,
  NUMBER_LIMIT_OF_SELECTED_DOCUMENTS_FOR_REGENERATE_ANSWER,
  extractReferencesFromResponse,
  isItNoResult,
  findTheLanguagesOfSelectedSources,
  getSourcesMapByLanguage,
  getSourcesArrayByLanguage,
  MULTI_LANG_CODE,
};
