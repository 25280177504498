import { createStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import * as userManagement from './modules/userManagement';
import * as userProfileHelpers from './modules/userProfileHelpers';
import * as browseHelpers from './modules/browseHelpers';
import * as chatHelpers from './modules/chatHelpers';
import * as blogHelpers from './modules/blogHelpers';
import * as newsHelpers from './modules/newsHelpers';
import * as batchHelpers from './modules/batchHelpers';
import * as paddleHelpers from './modules/paddleHelpers';
import * as sourceHelpers from './modules/sourceHelpers';
import * as userTracking from './modules/userTracking';
import * as outputTemplateHelpers from './modules/outputTemplateHelpers';

import { defaultSearchTypes } from '@/utils/browse';
import { subscriptionStatusOptions } from '@/utils/paddle';
import {
  isOutputTemplateMadeByEvidenceHunt,
  OUTPUT_TEMPLATE_MODAL_PAGES,
} from '@/utils/outputTemplate';

function initialState() {
  return {
    customHeader: false,
    sideBanner: false,
    mobile: false,
    mobileSideMenu: false,
    contactModalFull: false,
    contactModal: false,
    enterpriseContactModal: false,
    onboardingModal: false,
    feedbackModal: false,
    chatDocs: [],
    chatHistory: [],
    chatAnswerParts: [],
    chatStandaloneQuestion: '',
    chatLoading: false,
    searchLoading: false,
    questionLoading: false,
    chatLoadingDocs: false,
    chatLoadingQuery: false,
    chatSearchQuery: '',
    chatNewMessage: '',
    chatMessages: [
      {
        user: 'ASSISTANT',
        content: '',
        responseQuality: 0,
        feedback: '',
      },
    ],
    chatLastQuestion: '',
    chatLastAnswer: '',
    chatCitations: [], // other references
    chatCitationsMenuOpened: false,
    chatUsedCitations: [], // used references that are linked to the chat
    // chatPdfPath: "",
    chatUsePubmed: true,
    chatPdfPath:
      'https://storage.googleapis.com/library-docs/003f7406-c856-458a-9a45-f8a93256792f.pdf',
    chatPdfPage: 1,
    chatPubmedDoc: {},
    chatDocType: 'pdf',
    chatSelectedCitation: null,
    chatPdfSource: '',
    chatSessionId: uuidv4(),
    chatCurrentPageHistory: 1,
    chatItemsPerPageHistory: 5,
    chatRandomUserId: uuidv4(),
    chatMainQuestion: '',
    chatPrevMainQuestion: '',
    chatSourceLanguage: 'en',
    chatLayman: false,
    chatCopySuccess: {},
    chatAskEmail: false,
    chatAskFeedback: false,
    chatFeedback: '',
    chatFeedbackQuestion: null,
    chatDeletingBusy: {},
    chatIsExpanded: false,
    chatWelcomeMessage: '',
    chatUsedIds: [],
    chatUseSource: 'pubmed',
    chatFilters: {
      sinceYear: null,
      studyTypes: ['randomized_trial', 'meta_analysis', 'systematic_review', 'human_intervention'],
      impactFactor: false,
      sampleSize: undefined,
    },
    chatGptMode: localStorage.getItem('chatGptMode')
      ? localStorage.getItem('chatGptMode') === 'true'
      : true,
    chatElaborateMode: localStorage.getItem('chatElaborateMode')
      ? localStorage.getItem('chatElaborateMode') === 'true'
      : true,
    isPaddlePaymenCancelationInProgress: false,
    isOnboardingNeeded: false,
    chatMobileSection: null,
    chatWithDocuments: [],
    chatSelectedSourcesInternational: [],
    chatSelectedSourcesRegional: [],
    chatSelectedSourcesCustom: [],
    chatSelectedLibrarySources: [],
    chatSelectedUsedCitations: [],
    chatSelectedOtherCitations: [],
    chatUnselectedUsedCitations: [],
    chatToggleUsePubmed: true,
    chatFirstView: true,
    chatOutputTemplateModal: false,
    stayInChat: false,
    locale: localStorage.getItem('selectedLocale')
      ? localStorage.getItem('selectedLocale') === 'nl'
        ? 'nl'
        : 'en'
      : navigator.language.toLowerCase().includes('nl')
      ? 'nl'
      : 'en',
    isUserLoading: false,
    userProfile: {},
    isUserVerified: false,
    userProfileModal: false,
    upgradeToProModal: false,
    newUserAuthRedirectedUrl: '',
    loading: true,
    docs: [],
    initDocs: [],
    showRecent: true, // also shows whether a search has been initiated
    summary: '',
    initSummary: '',
    searchTab: 0,
    searchMobileSection: null,
    changedSearchTabFrom: null, // use to store the tab we will return to when click "back to results
    numDocs: 0,
    initNumDocs: 0,
    numPages: 0,
    initNumPages: 0,
    queries: {},
    showPageSkeleton: false,
    uniqueDisciplines: [],
    current_pubmed: '',
    current_qids: [],
    current_pico: {
      population_must: '',
      intervention_must: '',
      population_at_least: '',
      intervention_at_least: '',
      population_must_not: '',
      intervention_must_not: '',
    },
    sort_order: '',
    article_type_list: ['randomized_trial'],
    totalNumberDocs: 0,
    currentWeek: true,

    // mutated and called by multiple components
    s_is_shown: false,
    s_search_current_week: false,
    s_sort_by: 'impact_factor',
    s_sort_options: [
      { key: 'ml_n', labelKey: 'search.sortOptions.sampleSize' },
      { key: 'publication_date', labelKey: 'search.sortOptions.recency' },
      { key: 'impact_factor', labelKey: 'search.sortOptions.impactFactor' },
    ],
    s_sort_order_options: [
      { label: 'ascending', key: 'asc' },
      { label: 'descending', key: 'desc' },
    ],
    s_sort_order: 'desc',
    s_page: 1,
    s_fuzziness: 1,
    s_article_type: defaultSearchTypes,
    s_article_options: [
      {
        labelKey: 'search.studyTypes.randomizedTrial',
        key: 'randomized_trial',
      },
      { labelKey: 'search.studyTypes.metaAnalysis', key: 'meta_analysis' },
      {
        labelKey: 'search.studyTypes.systematicReview',
        key: 'systematic_review',
      },
      {
        labelKey: 'search.studyTypes.clinicalIntervention',
        key: 'human_intervention',
      },
      { labelKey: 'search.studyTypes.rodentStudy', key: 'rodent' },
      { labelKey: 'search.studyTypes.protocol', key: 'protocol' },
      { labelKey: 'search.studyTypes.other', key: 'misc' },
    ],
    s_search_type: 'pico',
    s_simple: true,
    email: '',
    title: '',
    institution: '',

    blog_list: null,
    blog_article: null,
    news_list: null,
    news_article: null,
    monthPrice: 0,
    annualyPrice: 0,
    monthCurrencyCode: '',
    annualyCurrencyCode: '',
    auth_redirected: false,
    paddleTransactionId: '',
    trial_interval_month: null,
    trial_frequency_month: null,
    trial_interval_annual: null,
    trial_frequency_annual: null,
    paddlePaymentInProgress: false,
    userForVerificationLink: {},
    isVerificationFromLogin: false,
    discount: {},
    discountAmount: '',
    // Free pro usage
    currentWeekProUsage: 0,
    maxWeekProUsage: 5,
    // Sources.
    sources: new Map(),
    isSourcesLoading: false,
    isSourceRefreshing: false,
    // Organization related
    organizations: [],
    organization: null,
    invites: [],
    predefinedOccupations: [],
    predefinedInstitutions: [],
    // Batch upload
    sourceBusyWithUploading: undefined,
    selectedFilesToUpload: [],
    filesToUpload: new Map(),
    batchHashes: new Set(),
    batchInfos: new Map(),
    organizationUploadersInfo: new Map(),
    sourceBucketOptions: undefined,
    hashQueue: [],
    uploadQueue: [],
    abortHashing: undefined,
    abortUploading: undefined,
    abortSourceRefreshing: undefined,
    activeHashWorkers: new Set(),
    activeUploadWorkers: new Set(),
    refreshDocuments: undefined,
    failedFiles: [],
    filesBySource: {},
    isUserOffline: false,
    // Output Templates
    isActiveOutputTemplateModalOpen: false,
    activeOutputTemplateModalContent: OUTPUT_TEMPLATE_MODAL_PAGES.ANSWER_FORMATTER,
    outputTemplateListResource: undefined,
    recentlyUsedOutputTemplateListResource: undefined,
    selectedOutputTemplateResource: undefined,
    answerFormat: '',
    isAnswerFormatAlwaysOn: false,
    inAnswerFormatSet: false,
    isDocumentRefreshing: false,
  };
}

export default createStore({
  state: initialState,
  getters: {
    isLoggedIn: (state) => Boolean(state.userProfile && state.userProfile.uid),
    getSourcesCount: (state, getters) => getters.chatSelectedSources.length + state.chatUsePubmed,
    isNewChat: (state) => state.chatMessages.length === 1,
    remainingWeekProUsage: (state) => {
      const remaining = state.maxWeekProUsage - state.currentWeekProUsage;
      return remaining < 0 ? 0 : remaining;
    },
    isPayingUser: (state) => {
      if (!state.userProfile?.uid) return false;
      const { subscriptionStatus } = state.userProfile;
      return [...subscriptionStatusOptions.active, ...subscriptionStatusOptions.trialing].includes(
        subscriptionStatus
      );
    },
    isPaying: (state) => {
      if (!state.userProfile?.uid) return false;
      if (state.userProfile?.sandbox) {
        return true;
      } else {
        const { subscriptionStatus, organizationRoles } = state.userProfile;
        // TODO: only use subscriptionStatus from active organization role
        return (
          [...subscriptionStatusOptions.active, ...subscriptionStatusOptions.trialing].includes(
            subscriptionStatus
          ) ||
          (organizationRoles || []).filter(
            (role) => role.organization?.subscriptionStatus === 'active'
          ).length > 0
        );
      }
    },
    isPro: (state, getters) => {
      if (!state.userProfile?.uid) return false;
      if (state.userProfile?.sandbox) {
        return true;
      } else {
        const paying = getters.isPaying;
        const remainingProUsage = state.maxWeekProUsage - state.currentWeekProUsage;
        return paying || remainingProUsage > 0;
      }
    },
    isSandbox: (state) => {
      if (!state.userProfile) return false;
      const sandbox = state.userProfile?.sandbox;
      const organization = state.organization;
      return !organization && sandbox;
    },
    isSuperAdmin: (state) => {
      if (!state.userProfile) return false;
      return state.userProfile?.superAdmin;
    },
    isEnglishUser: (state) => state.locale === 'en',
    isOrganizationAdmin: (state) => {
      if (!state.userProfile) return false;
      const { organizationRoles } = state.userProfile;
      const organization = state.organization;
      return (organizationRoles || []).find(
        (orgRole) =>
          orgRole?.role === 'admin' &&
          // This is needed to ensure users with roles to a defunct organization do not break the code.
          orgRole.organization &&
          orgRole.organization.id === organization?.id
      );
    },
    canPerformOutputTemplate: (_, getters) => {
      return getters.isLoggedIn && (getters.isOrganizationMember || getters.isPaying);
    },
    hasOrganizationAdminRole: (state) => {
      if (!state.userProfile) return false;
      const { organizationRoles } = state.userProfile;
      return !!(organizationRoles || []).some((orgRole) => orgRole?.role === 'admin');
    },
    isMultipleMember: (state) => {
      if (!state.userProfile) return false;
      const { organizationRoles } = state.userProfile;
      return (organizationRoles || []).filter((r) => !!r.organization).length > 1;
    },
    isOrganizationMember: (state) => {
      if (!state.userProfile) return false;
      const { organizationRoles } = state.userProfile;
      return (organizationRoles || []).filter((a) => !!a.organization).length >= 1;
    },
    isCurrentOrganizationMember: (state) => {
      if (!state.userProfile) return false;
      const { organizationRoles } = state.userProfile;
      return !!(organizationRoles || []).find((a) => a.organization?.id === state.organization?.id);
    },
    isWebSearchSource: (_, getters) => (id) => {
      return getters.getWebSearchSources?.value?.replace('*', '')?.split(',').includes(id);
    },
    displayName: (state) => {
      if (!state.userProfile) return '';
      const { email, firstName, lastName } = state.userProfile;
      if (firstName && lastName) return `${firstName} ${lastName}`;
      if (firstName) return firstName;
      if (lastName) return lastName;
      return email;
    },
    chatSelectedSources: (state) => {
      const newArray = [];
      state.chatSelectedLibrarySources.forEach((sourceId) => {
        let sourceIdWithoutSign = sourceId.replace('*', '');
        const splittedArray = sourceIdWithoutSign.split(',');
        newArray.push(...splittedArray);
      });

      return newArray;
    },
    hasUserMissingPersonalFields: (state, getters) =>
      getters.isLoggedIn && (!state.userProfile?.occupation || !state.userProfile?.institution),

    getDocs: (state) => (state.showRecent ? state.initDocs : state.docs),
    getNumDocs: (state) => (state.showRecent ? state.initNumDocs : state.numDocs),
    getNumPages: (state) => (state.showRecent ? state.initNumPages : state.numPages),
    getSummary: (state) => (state.showRecent ? state.initSummary : state.summary),
    getChatAnswerFormat: (state) => state.answerFormat,
    getChatOutputTemplateModal: (state) => state.chatOutputTemplateModal,
    getChatUsedCitations: (state) => state.chatUsedCitations,
    getChatLastQuestion: (state) => state.chatLastQuestion,
    getChatLastAnswer: (state) => state.chatLastAnswer,
    getDocumentsOfSource: (state) => (sourceId) => {
      return state.filesBySource[sourceId] || { documents: [], '@meta': { total: 0 } };
    },
    getDefaultSources: (state) =>
      [...state.sources.values()].filter(
        (source) =>
          source.maintainedBy &&
          source.maintainedBy['@type'] === 'EvidenceHunt' &&
          source.maintainedBy.id === 'evidencehunt'
      ),
    getOrganizationSources: (state) => {
      const organization = state.organization;
      if (!organization) {
        return [];
      }
      return [...state.sources.values()].filter(
        (source) =>
          source.maintainedBy &&
          source.maintainedBy['@type'] === 'Organization' &&
          source.maintainedBy.id === organization.id
      );
    },
    getOptionsListOfOrganizationSource: (_, getters) =>
      getters.getOrganizationSources.map((source) => ({
        value: source.id,
        text: source.name,
        languages: source.languages,
      })),
    getOptionsListOfDefaultSource: (_, getters) =>
      getters.getDefaultSources.map((source) => ({
        value: source.id,
        text: source.name,
        languages: source.languages,
      })),
    getWebSearchSources: (state) => {
      const sources = state.sources;
      const webSources = Array.from(sources.values()).filter(
        (a) => a.maintainedBy && a.maintainedBy['@type'] === 'WebSearch'
      );
      const webSearchValues = {
        value: '*',
        textLabel: 'chat.webSearch',
        languages: new Set(),
      };
      webSources.forEach((source, index) => {
        webSearchValues.value += source.id;
        webSearchValues.languages.add(...source.languages);
        if (index < webSources.length - 1) {
          webSearchValues.value += ',';
        }
      });
      webSearchValues.languages = [...webSearchValues.languages];
      return state.locale === 'nl' ? webSearchValues : undefined;
    },
    // Output template getters
    isAnswerFormatActive: (state) => {
      return state.inAnswerFormatSet || state.isAnswerFormatAlwaysOn;
    },
    isRegenerateAnswerAvailable: (state) => {
      const answerFormat = state.answerFormat;
      return typeof answerFormat === 'string' && answerFormat.length > 0;
    },
    isAnswerFormatValid: (state) => {
      const answerFormat = state.answerFormat;
      return typeof answerFormat === 'string' && answerFormat.length > 0;
    },
    shouldCreateNewOutputTemplate: (state) => {
      return (
        !state.selectedOutputTemplateResource ||
        isOutputTemplateMadeByEvidenceHunt(state.selectedOutputTemplateResource)
      );
    },
    hasAnswerFormatChanged: (state, getters) => {
      if (!state.selectedOutputTemplateResource) {
        // In case a new format, consider it changed if it is valid
        return getters.isAnswerFormatValid;
      }

      return state.selectedOutputTemplateResource.prompt !== state.answerFormat;
    },
    canSaveAnswerFormat: (state, getters) => {
      // Invalid formats can never be saved
      if (!getters.isAnswerFormatValid) {
        return false;
      }

      return getters.hasAnswerFormatChanged;
    },
    getSelectedOutputFormat: (state) => {
      return state.selectedOutputTemplateResource?.prompt || '';
    },
    getSelectedOutputTemplate: (state) => {
      return state.selectedOutputTemplateResource;
    },
    getSourceNameByReference: (state) => (reference) => {
      const { type } = reference;
      const sourceValue = type === 'pdf' ? reference.source : type;
      const valuesOfSources = Array.from(state.sources.values());
      const source = valuesOfSources.find((s) => s.id === sourceValue);

      return source ? source.name : sourceValue;
    },
    getReferenceContentLine:
      (_, getters) =>
      (reference, forcePubmed = false) => {
        const isPubmed = reference.type === 'pubmed' || forcePubmed;
        const resultList = [];
        if (isPubmed) {
          const { journal } = reference;
          const author = reference.first_author;
          const year = reference.date ? new Date(reference.date).getFullYear() : null;
          const details = [journal, author, year, `PMID: ${reference.pmid}`];
          resultList.push(reference.title, ...details);
        } else if (reference.type === 'pdf') {
          resultList.push(getters.getSourceNameByReference(reference), `Page: ${reference.page}`);
        }
        return resultList.filter((el) => !!el).join(' | ');
      },
  },
  mutations: {
    SET_STATE_PROPERTY(state, { property, value }) {
      if (Object.prototype.hasOwnProperty.call(state, property)) {
        state[property] = value;
      }
    },
    SET_CHAT_FILTER(state, { property, value }) {
      if (Object.prototype.hasOwnProperty.call(state.chatFilters, property)) {
        state.chatFilters[property] = value;
      }
    },
    SET_INIT_DOCS(state) {
      this.commit('SET_STATE_PROPERTY', { property: 'initDocs', value: [...state.docs] });
      this.commit('SET_STATE_PROPERTY', { property: 'initSummary', value: state.summary });
      this.commit('SET_STATE_PROPERTY', { property: 'initNumPages', value: state.numPages });
      this.commit('SET_STATE_PROPERTY', { property: 'initNumDocs', value: state.numDocs });
    },
    USE_INIT_DOCS(state) {
      this.commit('SET_STATE_PROPERTY', {
        property: 'docs',
        value: [...state.initDocs].map((doc) => ({ ...doc, selected: false })),
      });
      this.commit('SET_STATE_PROPERTY', { property: 'summary', value: state.initSummary });
      this.commit('SET_STATE_PROPERTY', { property: 'numPages', value: state.initNumPages });
      this.commit('SET_STATE_PROPERTY', { property: 'numDocs', value: state.initNumDocs });
    },
    RESET(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    ADD_TO_UPLOAD_QUEUE(state, file) {
      state.uploadQueue.push(file);
    },
    ADD_HASH_WORKER(state, worker) {
      state.activeHashWorkers.add(worker);
    },
    REMOVE_HASH_WORKER(state, worker) {
      state.activeHashWorkers.delete(worker);
    },
    ADD_UPLOAD_WORKER(state, worker) {
      state.activeUploadWorkers.add(worker);
    },
    REMOVE_UPLOAD_WORKER(state, worker) {
      state.activeUploadWorkers.delete(worker);
    },
    ADD_TO_BATCH_HASHES(state, hash) {
      state.batchHashes.add(hash);
    },
    SET_HASH_ABORT(state, controller) {
      if (controller?.constructor !== AbortController) {
        throw new Error('Provided controller is invalid.');
      }

      if (state.abortHashing?.constructor === AbortController) {
        state.abortHashing.abort();
      }

      state.abortHashing = controller;
    },
    ABORT_HASHING(state) {
      if (state.abortHashing?.constructor === AbortController) {
        state.abortHashing.abort();
      }

      state.abortHashing = undefined;
    },
    SET_SOURCE_REFRESH_ABORT(state, controller) {
      if (controller?.constructor !== AbortController) {
        throw new Error('Provided controller is invalid.');
      }

      if (state.abortSourceRefreshing?.constructor === AbortController) {
        state.abortSourceRefreshing.abort();
      }

      state.abortSourceRefreshing = controller;
    },
    ABORT_SOURCE_REFRESH(state) {
      if (state.abortSourceRefreshing?.constructor === AbortController) {
        state.abortSourceRefreshing.abort();
      }

      state.abortSourceRefreshing = undefined;
      state.isSourceRefreshing = false;
      state.isDocumentRefreshing = false;
    },
    SET_UPLOAD_ABORT(state, controller) {
      if (controller?.constructor !== AbortController) {
        throw new Error('Provided controller is invalid.');
      }

      if (state.abortUploading?.constructor === AbortController) {
        state.abortUploading.abort();
      }

      state.abortUploading = controller;
    },
    ABORT_UPLOADING(state) {
      if (state.abortUploading?.constructor === AbortController) {
        state.abortUploading.abort();
      }

      state.abortUploading = undefined;
    },
    SET_BATCH_INFO(state, info) {
      state.batchInfos.set(info['@id'], info);
    },
    SET_ORGANIZATION_UPLOADERS_INFO(state, info) {
      state.organizationUploadersInfo.set(info['@id'], info);
    },

    SET_DOCUMENT_REFRESHER(state, refresh) {
      state.refreshDocuments = refresh;
    },
    REMOVE_DOCUMENT_REFRESHER(state) {
      state.refreshDocuments = undefined;
    },
    ADD_TO_FAILED_FILE_LIST(state, file) {
      state.failedFiles.push(file);
    },
    ADD_SOURCE_FILES(state, { sourceId, data }) {
      state.filesBySource[sourceId] = data;
    },
    UPDATE_FILE_STATUS_IN_SOURCE_FILES(state, { fileId, status }) {
      if (!state.sourceBusyWithUploading) return;
      const sourceFiles = state.filesBySource[state.sourceBusyWithUploading?.id];
      if (!sourceFiles) return;
      const file = sourceFiles.documents.find((f) => f.id === fileId);
      if (!file) return;
      file.status = status;
    },
    // Output template mutations
    ENABLE_ACTIVE_ANSWER_FORMAT: (state) => {
      state.inAnswerFormatSet = true;
    },
    DISABLE_ACTIVE_ANSWER_FORMAT: (state) => {
      state.inAnswerFormatSet = false;
      state.isAnswerFormatAlwaysOn = false;
    },
    NAVIGATE_TO_ANSWER_FORMATTING_PAGE: (state) => {
      state.chatOutputTemplateModal = true;
      state.activeOutputTemplateModalContent = OUTPUT_TEMPLATE_MODAL_PAGES.ANSWER_FORMATTER;
    },
    NAVIGATE_TO_ALL_TEMPLATES_PAGE: (state) => {
      state.chatOutputTemplateModal = true;
      state.activeOutputTemplateModalContent = OUTPUT_TEMPLATE_MODAL_PAGES.ALL_TEMPLATES;
    },
    CLEAR_SELECTED_TEMPLATE: (state) => {
      state.selectedOutputTemplateResource = undefined;
      state.answerFormat = '';
      state.inAnswerFormatSet = false;
    },
    CONSUME_ANSWER_FORMAT: (state) => {
      if (!state.isAnswerFormatAlwaysOn) {
        state.inAnswerFormatSet = false;
      }
    },
    SET_SELECTED_OUTPUT_TEMPLATE: (state, outputTemplate) => {
      state.selectedOutputTemplateResource = outputTemplate;
      state.answerFormat = outputTemplate.prompt;
    },
    SET_IS_ANSWER_FORMAT_ALWAYS_ON: (state, alwaysOn) => {
      // This action has no meaning or purpose if no output template is selected
      if (!state.selectedOutputTemplateResource) {
        return;
      }

      state.isAnswerFormatAlwaysOn = alwaysOn;
    },
    UPDATE_ANSWER_FORMAT: (state, newPrompt = '') => {
      state.answerFormat = newPrompt;
    },
    ADD_TO_FETCHED_OUTPUT_TEMPLATES: (state, newOutputTemplate) => {
      state.recentlyUsedOutputTemplateListResource?.outputTemplates?.unshift(newOutputTemplate);
      state.recentlyUsedOutputTemplateListResource?.outputTemplates?.splice(-1, 1);
    },
    PATCH_FETCHED_OUTPUT_TEMPLATES: (state, patchedOutputTemplate) => {
      const patchMapFunction = (outputTemplate) =>
        outputTemplate.id === patchedOutputTemplate.id ? patchedOutputTemplate : outputTemplate;

      if (state.outputTemplateListResource) {
        const updatedTemplateList =
          state.outputTemplateListResource.outputTemplates?.map(patchMapFunction);
        state.outputTemplateListResource.outputTemplates = updatedTemplateList;
      }

      if (state.recentlyUsedOutputTemplateListResource) {
        const updatedTemplateList =
          state.recentlyUsedOutputTemplateListResource.outputTemplates?.map(patchMapFunction);
        state.recentlyUsedOutputTemplateListResource.outputTemplates = updatedTemplateList;
      }

      if (state.selectedOutputTemplateResource?.id === patchedOutputTemplate.id) {
        state.selectedOutputTemplateResource = patchedOutputTemplate;
      }
    },
    DELETE_OUTPUT_TEMPLATE: (state, deletedOutputTemplate) => {
      const deleteFilter = (outputTemplate) => outputTemplate.id !== deletedOutputTemplate.id;

      if (state.outputTemplateListResource) {
        const updatedTemplateList =
          state.outputTemplateListResource.outputTemplates?.filter(deleteFilter);
        state.outputTemplateListResource.outputTemplates = updatedTemplateList;
      }

      if (state.recentlyUsedOutputTemplateListResource) {
        const updatedTemplateList =
          state.recentlyUsedOutputTemplateListResource.outputTemplates?.filter(deleteFilter);
        state.recentlyUsedOutputTemplateListResource.outputTemplates = updatedTemplateList;
      }

      if (state.selectedOutputTemplateResource) {
        state.selectedOutputTemplateResource = undefined;
        state.answerFormat = '';
      }
    },
  },
  actions: {
    ...userManagement,
    ...browseHelpers,
    ...chatHelpers,
    ...blogHelpers,
    ...newsHelpers,
    ...paddleHelpers,
    ...userTracking,
    ...batchHelpers,
    ...sourceHelpers,
    ...userProfileHelpers,
    ...outputTemplateHelpers,
  },
});
